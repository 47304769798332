import { AriaLiveNotificationsService } from './AriaLiveNotificationsService';
import each from 'lodash/each';

export const DEBOUNCE_MESSAGE_DELAY = 500;
export const IN_PROGRESS_MESSAGE_DELAY = 3000;

/**
 * Options for the notification operation.
 */
export interface AriaLiveNotificationsOptions {
    /**
     * The delay after which the notification is announced.
     */
    delay?: number;
    /**
     * Clear the current stack of notifications
     */
    clearCurrentNotifications?: boolean;
}

let politeNotificationService: AriaLiveNotificationsService | null;
let assertiveNotificationService: AriaLiveNotificationsService | null;

export function getContainerNode(): HTMLElement {
    return document.querySelector('#aria-live-container') as HTMLElement;
}

function checkAssertiveNotifServiceInitialized(): void {
    if (assertiveNotificationService == null) {
        assertiveNotificationService = new AriaLiveNotificationsService(getContainerNode(), false);
    }
}

function checkPoliteNotifServiceInitialized(): void {
    if (politeNotificationService == null) {
        politeNotificationService = new AriaLiveNotificationsService(getContainerNode(), true);
    }
}

/**
 * Clear all assertive notifications.
 * Besides cleanup, this method should be used before sending a new notification.
 */
export function clearAssertive(): void {
    checkAssertiveNotifServiceInitialized();

    if (assertiveNotificationService) {
        assertiveNotificationService.clearNotifications();
    }
}

/**
 * Clear all polite notifications.
 * Besides cleanup, this method should be used before sending a new notification.
 */
export function clearPolite(): void {
    checkPoliteNotifServiceInitialized();

    if (politeNotificationService) {
        politeNotificationService.clearNotifications();
    }
}

/**
 * Remove the polite and assertive elements from the #aria-live-container
 */
export function clear(): void {
    if (assertiveNotificationService != null) {
        assertiveNotificationService.destroy();
        assertiveNotificationService = null;
    }
    if (politeNotificationService != null) {
        politeNotificationService.destroy();
        politeNotificationService = null;
    }
}

function mergeWithDefaultOptions(options?: AriaLiveNotificationsOptions): AriaLiveNotificationsOptions {
    return {
        clearCurrentNotifications: true,
        delay: 0,
        ...options,
    };
}

/**
 * Send a polite notification. This should be the main method used, as it works in almost all cases.
 * @param message               The message of the notification.
 * @param options               The options for the this notification
 * @return - number which is ID of timer (can be used for canceling the timer)
 */
export function notifyPolite(message: string, options?: AriaLiveNotificationsOptions): number {
    options = mergeWithDefaultOptions(options);
    checkPoliteNotifServiceInitialized();
    if (options.clearCurrentNotifications) {
        clearPolite();
    }
    return politeNotificationService!.notify(message, options.delay);
}

/**
 * Send multiple polite notifications.
 */
export function notifyManyPolite(...messages: string[]): void {
    checkPoliteNotifServiceInitialized();
    each(messages, (message: string): void => {
        politeNotificationService!.notify(message);
    });
}

/**
 * Send a assertive notification. In general, this should be used sparingly, and mainly for error states,
 * because an assertive notification will interrupt all polite notifications, even those which were not started yet.
 * @param message               The message of the notification.
 * @param options               The options for the this notification
 * @return - number which is ID of timer (can be used for canceling the timer)
 */
export function notifyAssertive(message: string, options?: AriaLiveNotificationsOptions): number {
    options = mergeWithDefaultOptions(options);
    checkAssertiveNotifServiceInitialized();
    if (options.clearCurrentNotifications) {
        clearAssertive();
    }
    return assertiveNotificationService!.notify(message, options.delay);
}

/**
 * Send multiple assertive notifications.
 * @param messages The messages of the notifications.
 */
export function notifyManyAssertive(...messages: string[]): void {
    checkAssertiveNotifServiceInitialized();
    each(messages, (message: string): void => {
        assertiveNotificationService!.notify(message);
    });
}
