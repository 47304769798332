export enum AuthActionType {
    AUTHENTICATE_REQUEST = 'AUTH/AUTHENTICATE_REQUEST',
    AUTHENTICATE_SUCCESS = 'AUTH/AUTHENTICATE_SUCCESS',
    AUTHENTICATE_ERROR = 'AUTH/AUTHENTICATE_ERROR',

    DEAUTHENTICATE = 'AUTH/DEAUTHENTICATE',
    DEAUTHENTICATE_SUCCESS = 'AUTH/DEAUTHENTICATE_SUCCESS',

    TOKEN_REQUEST = 'AUTH/TOKEN_REQUEST',
    TOKEN_SUCCESS = 'AUTH/TOKEN_SUCCESS',
    TOKEN_ERROR = 'AUTH/TOKEN_ERROR',

    SEND_RESET_PASSWORD_REQUEST = 'AUTH/SEND_RESET_PASSWORD_REQUEST',
    SEND_RESET_PASSWORD_REQUEST_SUCCESS = 'AUTH/SEND_RESET_PASSWORD_REQUEST_SUCCESS',
    SEND_RESET_PASSWORD_REQUEST_ERROR = 'AUTH/SEND_RESET_PASSWORD_REQUEST_ERROR',

    RESET_PASSWORD_EMAIL_LOOKUP_SUCCESS = 'AUTH/RESET_PASSWORD_EMAIL_LOOKUP_SUCCESS',
    RESET_PASSWORD_EMAIL_LOOKUP_ERROR = 'AUTH/RESET_PASSWORD_EMAIL_LOOKUP_ERROR',

    UPDATE_PASSWORD = 'AUTH/UPDATE_PASSWORD',
    UPDATE_PASSWORD_SUCCESS = 'AUTH/UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_ERROR = 'AUTH/UPDATE_PASSWORD_ERROR',

    CONFIRM_EMAIL = 'AUTH/CONFIRM_EMAIL',
    CONFIRM_EMAIL_SUCCESS = 'AUTH/CONFIRM_EMAIL_SUCCESS',
    CONFIRM_EMAIL_ERROR = 'AUTH/CONFIRM_EMAIL_ERROR',
}
