import { SagaIterator } from 'redux-saga';
import { put, call, select, cancelled } from 'redux-saga/effects';
import { AppState } from '../store/reducers';
import { authenticatedTokenRequest, tokenSuccess, tokenError, AuthState, waitForAuthentication } from '../modules/auth';
import axios, { AxiosResponse, AxiosPromise, CancelToken } from 'axios';
import { AnyAction } from 'redux';
import { stringify } from 'qs';
import curlirize from 'axios-curlirize';
import jwt from 'jsonwebtoken';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { HttpStatusCode } from '../domain/HttpStatusCode';
import { Locale } from '../modules/locale';
import { notifyPolite, IN_PROGRESS_MESSAGE_DELAY, notifyAssertive } from '../components/common/A11y/Notification';

if (process.env.NODE_ENV !== 'production' && process.env.DISPLAY_CURL && typeof window !== 'object') {
    curlirize(axios);
}

export interface RequestConfig {
    url: string;
    method?: 'GET' | 'POST' | 'HEAD' | 'PUT' | 'DELETE';
    params?: object;
    body?: object;
    additionalHeaders?: Record<string, string>;
}

export interface RequestError {
    status: HttpStatusCode;
    statusText: string;
    description: string;
}

export interface APICallAriaLiveNotifMessages {
    /**
     * message to be aria-lived if action does not complete quickly
     */
    inProgressMessage: string;

    /**
     * message to be aria-lived when action successfully completes
     */
    completedMessage: string;

    /**
     * message to be aria-lived when action fails
     */
    ErrorMessage: string;
}

export interface APICall {
    endpoint: string | RequestConfig | (string | RequestConfig)[];
    onSuccess: (...resp: AxiosResponse[]) => AnyAction;
    onError: (error: RequestError) => AnyAction;

    /**
     * Optional (but recommended) messages to be announced
     * as ARIA Live notifications while an XHR is in progress/failed/completed.
     */
    ariaLiveNotifMessages?: APICallAriaLiveNotifMessages;
}

const selectAuth = (state: AppState): AuthState => state.auth;

const CLIENT_CREDENTIALS_GRANT_URL =
    process.env.CLIENT_CREDENTIALS_GRANT_URL ||
    'https://ifcinema-auth-qa.noop.fr/oauth/token?grant_type=client_credentials';

const CLIENT_CREDENTIALS_AUTH = process.env.CLIENT_CREDENTIALS_AUTH || 'ZnJvbnQtYW5vbnltb3VzOmVleDZ0aGFpa28zQWlrYTk=';

function fetchAnonymousJWT(cancelToken: CancelToken): Promise<AxiosResponse> {
    return axios({
        url: CLIENT_CREDENTIALS_GRANT_URL,
        method: 'POST',
        headers: {
            Authorization: `Basic ${CLIENT_CREDENTIALS_AUTH}`,
        },
        cancelToken,
    });
}

const API_BASE_URL: string = process.env.API_URL || 'https://ifcinema-api-qa.noop.fr';

function doRequest(
    endpoint: RequestConfig | string,
    locale: Locale,
    accessToken: string,
    cancelToken: CancelToken,
    ip?: string
): AxiosPromise {
    if (typeof endpoint === 'string') {
        endpoint = {
            url: endpoint,
        };
    }
    let url = `${API_BASE_URL}/${endpoint.url}`;
    if (endpoint.params) {
        url = `${url}?${stringify(endpoint.params, { indices: false })}`;
    }

    const headers: Record<string, string> = {
        Authorization: `Bearer ${accessToken}`,
        'Accept-Language': locale,
        ...endpoint.additionalHeaders,
    };

    if (ip) {
        headers['X-Forwarded-For'] = ip;
    }

    return axios({
        url,
        data: endpoint.body,
        method: endpoint.method || 'GET',
        headers,
        cancelToken,
    });
}

function fetchAPI(
    { endpoint }: APICall,
    locale: Locale,
    accessToken: string,
    cancelToken: CancelToken,
    ip?: string
): Promise<AxiosResponse | AxiosResponse[]> {
    if (!accessToken) {
        throw new Error('No token found');
    }
    if (!isArray(endpoint)) {
        return doRequest(endpoint, locale, accessToken, cancelToken, ip);
    }

    return Promise.all(
        endpoint.map(
            (config: RequestConfig | string): AxiosPromise => {
                return doRequest(config, locale, accessToken, cancelToken, ip);
            }
        )
    );
}

const hasValidToken = (token?: string): boolean => {
    if (!token) {
        return false;
    }
    const { exp } = jwt.decode(token) as { exp: number };
    return exp > Date.now() / 1000;
};

function* fetchAndPut(apiCall: APICall, accessToken: string, cancelToken: CancelToken): SagaIterator {
    const locale = yield select((state: AppState): Locale => state.locale);
    const ip = yield select((state: AppState): string => state.ip);
    const apiRes = yield call(fetchAPI, apiCall, locale, accessToken, cancelToken, ip);
    if (isArray(apiRes)) {
        yield put(apiCall.onSuccess.apply(apiCall, apiRes));
    } else {
        yield put(apiCall.onSuccess(apiRes));
    }
}

/**
 * Wrap APICall onSuccess and onError callbacks with Aria-live notifications if necessary
 * Aria-live polite will be scheduled to announce that an action is in progress
 * if the call does not complete within short time (IN_PROGRESS_MESSAGE_DELAY)
 */
// FIXME: This is untested, particulary on server side.
function wrapCallbacksWithAriaAriaLiveNotifications(apiCall: APICall): APICall {
    if (apiCall.ariaLiveNotifMessages == null) {
        return apiCall;
    }

    const timer: number = notifyPolite(apiCall.ariaLiveNotifMessages.inProgressMessage, {
        delay: IN_PROGRESS_MESSAGE_DELAY,
    });

    const originalSuccessHandler: (...resp: AxiosResponse[]) => AnyAction = apiCall.onSuccess;
    const newSuccessHandler: (...resp: AxiosResponse[]) => AnyAction = (...resp: AxiosResponse[]): AnyAction => {
        clearTimeout(timer);
        notifyPolite(apiCall.ariaLiveNotifMessages!.completedMessage);
        return originalSuccessHandler.apply(originalSuccessHandler, resp);
    };

    const originalErrorHandler: (error: RequestError) => AnyAction = apiCall.onError;
    const newErrorHandler: (error: RequestError) => AnyAction = (error: RequestError): AnyAction => {
        clearTimeout(timer);
        notifyAssertive(apiCall.ariaLiveNotifMessages!.ErrorMessage);
        return originalErrorHandler(error);
    };

    return {
        ...apiCall,
        onSuccess: newSuccessHandler,
        onError: newErrorHandler,
    };
}

export function* callApi(apiCall: APICall): SagaIterator {
    apiCall = wrapCallbacksWithAriaAriaLiveNotifications(apiCall);
    const cancelSource = axios.CancelToken.source();
    try {
        let auth = yield select(selectAuth);
        if (!hasValidToken(auth.token)) {
            try {
                let shouldWaitForAuthenticatedToken = false;
                if (auth.authenticatedOnOAuthServer) {
                    yield put(authenticatedTokenRequest());
                    shouldWaitForAuthenticatedToken = true;
                } else {
                    const apiRes = yield call(fetchAnonymousJWT, cancelSource.token);
                    yield put(tokenSuccess(apiRes.data.access_token));
                }

                if (shouldWaitForAuthenticatedToken) {
                    yield call(waitForAuthentication);
                }
            } catch (err) {
                yield put(tokenError(err as string));
            }
            auth = yield select(selectAuth);
        }

        if (auth.token) {
            yield call(fetchAndPut, apiCall, auth.token, cancelSource.token);
        }
    } catch (err) {
        // NOTE : We cannot return the AxiosError directly because of circular references during
        // serialization.
        yield put(
            apiCall.onError({
                status: get(err, 'response.status', HttpStatusCode.INTERNAL_SERVER_ERROR),
                statusText: get(err, 'response.statusText', 'Internal server error'),
                description: get(err, 'response.data.message', 'N/A'),
            })
        );
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel);
        }
    }
}
