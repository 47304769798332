export enum ProfileActionType {
    FETCH = 'PROFILE/FETCH',
    FETCH_SUCCESS = 'PROFILE/FETCH_SUCCESS',
    FETCH_ERROR = 'PROFILE/FETCH_ERROR',
    CLEAR = 'PROFILE/CLEAR',
    UPDATE = 'PROFILE/UPDATE',
    UPDATE_SUCCESS = 'PROFILE/UPDATE_SUCCESS',
    UPDATE_ERROR = 'PROFILE/UPDATE_ERROR',
    EDIT_EMAIL = 'PROFILE/EDIT_EMAIL',
    EDIT_EMAIL_SUCCESS = 'PROFILE/EDIT_EMAIL_SUCCESS',
    RESET_EDIT_EMAIL_SUCCESS = 'PROFILE/RESET_EDIT_EMAIL_SUCCESS',
    EDIT_EMAIL_ERROR = 'PROFILE/EDIT_EMAIL_ERROR',
    EDIT_PASSWORD = 'PROFILE/EDIT_PASSWORD',
    EDIT_PASSWORD_SUCCESS = 'PROFILE/EDIT_PASSWORD_SUCCESS',
    EDIT_PASSWORD_ERROR = 'PROFILE/EDIT_PASSWORD_ERROR',
    RESET_EDIT_PASSWORD_SUCCESS = 'PROFILE/RESET_EDIT_PASSWORD_SUCCESS',
    DELETE_ACCOUNT = 'PROFILE/DELETE_ACCOUNT',
    DELETE_ACCOUNT_SUCCESS = 'PROFILE/DELETE_ACCOUNT_SUCCESS',
    DELETE_ACCOUNT_ERROR = 'PROFILE/DELETE_ACCOUNT_ERROR',
    REACTIVATE_ACCOUNT_REQUEST = 'PROFILE/REACTIVATE_ACCOUNT_REQUEST',
    REACTIVATE_ACCOUNT_REQUEST_SUCCESS = 'PROFILE/REACTIVATE_ACCOUNT_REQUEST_SUCCESS',
    REACTIVATE_ACCOUNT_REQUEST_ERROR = 'PROFILE/REACTIVATE_ACCOUNT_REQUEST_ERROR',
}
