import { ReferenceItem, TrackedObject } from './core';
import { Movie, WorkLanguage } from './work';
import { FrontUser } from './user';
import { VideoProduct } from './commerce';

export enum FavoriteType {
    CYCLE = 'cycle',
    MOVIE = 'movie',
    PLAYLIST = 'playlist',
}

export type Favorite = ReferenceItem & TrackedObject & FavoriteData;

export interface FavoriteData {
    favorite: ReferenceItem;
    type: FavoriteType;
}

export interface PlaylistEntryData {
    product: VideoProduct;
    movie: Movie;
    selectedSubtitle: WorkLanguage;
}

export type PlaylistEntry = ReferenceItem & PlaylistEntryData;

export interface PlaylistData {
    title: string;
    frontUser: FrontUser;
    entries: PlaylistEntry[];
    // movies: Movie[]; // FIXME: This is not in the DTO definition, see https://git.noop.fr/if/ifcinema-api-doc/blob/develop/docs/api/v1/components/schemas/community.yaml#L18
}

export type Playlist = PlaylistData & ReferenceItem & TrackedObject;

export type PlaylistUpdate = PlaylistData & ReferenceItem;

export interface ReviewData {
    movie: Partial<Movie>;
    frontUser: Partial<FrontUser>;
    review: string;
    title?: string;
    // rate: number;
}

export type Review = ReviewData & TrackedObject & ReferenceItem;
