import { uuid } from '../../domain/core';

export function getReactivationRequests(): uuid[] {
    const usersHavingRequestedReactivationUnserialized = localStorage.getItem('reactivationRequests');
    if (usersHavingRequestedReactivationUnserialized != null) {
        const usersHavingRequestedReactivation: uuid[] | null = JSON.parse(
            usersHavingRequestedReactivationUnserialized
        );
        if (usersHavingRequestedReactivation && usersHavingRequestedReactivation.length) {
            return usersHavingRequestedReactivation;
        }
    }
    return [];
}

export function saveReactivationRequests(reactivationRequests: uuid[]): void {
    localStorage.setItem('reactivationRequests', JSON.stringify(reactivationRequests));
}
