import { takeLatest, call, fork, take, select, put } from 'redux-saga/effects';
import { callApi } from '../../sagas/APICallSaga';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';
import { FrontUser, FrontUserUpdate } from '../../domain/user';
import {
    ProfileUpdateAction,
    ProfileUpdateSuccessAction,
    createProfileUpdateSuccessAction,
    createProfileUpdateErrorAction,
    createEditEmailSuccessAction,
    createEditEmailErrorAction,
    createEditPasswordSuccessAction,
    createEditPasswordErrorAction,
    createDeleteAccountSuccessAction,
    createDeleteAccountErrorAction,
    createFetchProfileSuccessAction,
    createFetchProfileErrorAction,
    ProfileFetchAction,
    ReactivateAccountRequestAction,
    createReactivateAccountRequestSuccessAction,
    createReactivateAccountRequestErrorAction,
    ReactivateAccountResponseAction,
    PasswordUpdate,
    EmailUpdate,
} from './ProfileActions';
import { ProfileActionType } from './ProfileActionType';
import { AppState } from '../../store/reducers';
import { uuid } from '../../domain/core';
import { getReactivationRequests, saveReactivationRequests } from './ProfileService';
import { deauthenticate, authenticationRequest, AuthActionType } from '../auth';

function* updateProfile({ payload: body }: ProfileUpdateAction<FrontUserUpdate>): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: `users`,
            method: 'PUT',
            body,
        },
        onSuccess: (response: AxiosResponse<FrontUser>): ProfileUpdateSuccessAction<FrontUser> =>
            createProfileUpdateSuccessAction(response.data),
        onError: createProfileUpdateErrorAction,
    });
}

function* editEmail({ payload: body }: ProfileUpdateAction<EmailUpdate>): SagaIterator {
    yield fork(callApi, {
        endpoint: {
            url: `users/email`,
            method: 'POST',
            body,
        },
        onSuccess: (response: AxiosResponse<FrontUser>): ProfileUpdateSuccessAction<{}> =>
            createEditEmailSuccessAction(response.data),
        onError: createEditEmailErrorAction,
    });

    yield take(ProfileActionType.EDIT_EMAIL_SUCCESS);
    yield put(deauthenticate());
    yield take(AuthActionType.DEAUTHENTICATE_SUCCESS);
    const { password, newEmail } = body;
    yield put(authenticationRequest({ email: newEmail, password }));
}

function* editPassword({ payload: body }: ProfileUpdateAction<PasswordUpdate>): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: `users/password`,
            method: 'POST',
            body,
        },
        onSuccess: (response: AxiosResponse<{}>): ProfileUpdateSuccessAction<{}> =>
            createEditPasswordSuccessAction(response.data),
        onError: createEditPasswordErrorAction,
    });
}

function* deleteAccount({ payload: body }: ProfileUpdateAction<{}>): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: `deleteAccount`, // FIXME: Change this once NoOp has created the endpoint.
            method: 'POST',
            body,
        },
        onSuccess: (response: AxiosResponse<{}>): ProfileUpdateSuccessAction<{}> =>
            createDeleteAccountSuccessAction(response.data),
        onError: createDeleteAccountErrorAction,
    });
}

function* fetchUserInfo(): SagaIterator {
    yield call(callApi, {
        endpoint: `users`,
        onSuccess: (res: AxiosResponse<FrontUser>): ProfileFetchAction => createFetchProfileSuccessAction(res.data),
        onError: createFetchProfileErrorAction,
    });
}

function* reactivateAccountRequest(action: ReactivateAccountRequestAction): SagaIterator {
    const body = new FormData();
    body.append('description', action.description);

    yield fork(callApi, {
        endpoint: {
            url: `account-enable-requests`,
            method: 'POST',
            body,
        },
        onSuccess: createReactivateAccountRequestSuccessAction,
        onError: createReactivateAccountRequestErrorAction,
    });

    const reactivateAccountResponseAction: ReactivateAccountResponseAction = yield take([
        ProfileActionType.REACTIVATE_ACCOUNT_REQUEST_SUCCESS,
        ProfileActionType.REACTIVATE_ACCOUNT_REQUEST_ERROR,
    ]);

    if (reactivateAccountResponseAction.type === ProfileActionType.REACTIVATE_ACCOUNT_REQUEST_SUCCESS) {
        const userId = yield select((state: AppState): uuid => state.profile.data!.id);
        const usersHavingRequestedReactivation = yield call(getReactivationRequests);
        usersHavingRequestedReactivation.push(userId);
        yield call(saveReactivationRequests, usersHavingRequestedReactivation);
    }
}

export default function* ProfileSaga(): SagaIterator {
    yield takeLatest(ProfileActionType.FETCH, fetchUserInfo);
    yield takeLatest(ProfileActionType.UPDATE, updateProfile);
    yield takeLatest(ProfileActionType.EDIT_EMAIL, editEmail);
    yield takeLatest(ProfileActionType.EDIT_PASSWORD, editPassword);
    yield takeLatest(ProfileActionType.DELETE_ACCOUNT, deleteAccount);
    yield takeLatest(ProfileActionType.REACTIVATE_ACCOUNT_REQUEST, reactivateAccountRequest);
}
