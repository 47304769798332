import { ProfileActionType } from './ProfileActionType';
import { Action, ActionCreator } from 'redux';
import { RequestError } from '../../sagas/APICallSaga';
import { FrontUser, FrontUserUpdate } from '../../domain/user';
import { FetchAction } from '../Fetch';

export type ProfileAction = Action<ProfileActionType>;

export type ProfileFetchAction = FetchAction<ProfileActionType, FrontUser>;

export interface PasswordUpdate {
    oldPassword: string;
    newPassword: string;
}

export interface EmailUpdate {
    password: string;
    newEmail: string;
}

export interface ProfileUpdateAction<P> extends ProfileAction {
    payload: P;
}

export interface ProfileUpdateSuccessAction<R> extends ProfileAction {
    response: R;
}

export interface ProfileUpdateErrorAction extends ProfileAction {
    error: RequestError;
}

export interface ReactivateAccountRequestAction extends ProfileAction {
    description: string;
}

export interface ReactivateAccountResponseAction extends ProfileAction {
    error?: RequestError;
}

export type ProfileUpdateActionCreator<P> = (payload: P) => ProfileUpdateAction<P>;

export type ProfileUpdateSuccessActionCreator<R> = (response: R) => ProfileUpdateSuccessAction<R>;

export type ProfileUpdateErrorActionCreator = ActionCreator<ProfileUpdateErrorAction>;

function createUpdateAction<P>(type: ProfileActionType): ProfileUpdateActionCreator<P> {
    return (payload: P): ProfileUpdateAction<P> => {
        return {
            type,
            payload,
        };
    };
}

function createUpdateSuccessAction<R>(type: ProfileActionType): ProfileUpdateSuccessActionCreator<R> {
    return (response: R): ProfileUpdateSuccessAction<R> => {
        return {
            type,
            response,
        };
    };
}

function createUpdateErrorAction(type: ProfileActionType): ProfileUpdateErrorActionCreator {
    return (error: RequestError): ProfileUpdateErrorAction => {
        return {
            type,
            error,
        };
    };
}

// Action creators related to profile update
export const createProfileUpdateAction = createUpdateAction<FrontUserUpdate>(ProfileActionType.UPDATE);
export const createProfileUpdateSuccessAction = createUpdateSuccessAction<FrontUser>(ProfileActionType.UPDATE_SUCCESS);
export const createProfileUpdateErrorAction = createUpdateErrorAction(ProfileActionType.UPDATE_ERROR);

// Action creators related to email update
// FIXME: Change generic once Noop has published the endpoint.
export const createEditEmailAction = createUpdateAction<EmailUpdate>(ProfileActionType.EDIT_EMAIL);
// FIXME: Change generic once Noop has published the endpoint.
export const createEditEmailSuccessAction = createUpdateSuccessAction<FrontUser>(ProfileActionType.EDIT_EMAIL_SUCCESS);
export const createEditEmailErrorAction = createUpdateErrorAction(ProfileActionType.EDIT_EMAIL_ERROR);

export function createResetEditEmailSuccessAction(): ProfileAction {
    return {
        type: ProfileActionType.RESET_EDIT_EMAIL_SUCCESS,
    };
}

// Action creators related to password update
// FIXME: Change generic once Noop has published the endpoint.
export const createEditPasswordAction = createUpdateAction<PasswordUpdate>(ProfileActionType.EDIT_PASSWORD);
// FIXME: Change generic once Noop has published the endpoint.
export const createEditPasswordSuccessAction = createUpdateSuccessAction<{}>(ProfileActionType.EDIT_PASSWORD_SUCCESS);
export const createEditPasswordErrorAction = createUpdateErrorAction(ProfileActionType.EDIT_PASSWORD_ERROR);

export function createResetEditPasswordSuccessAction(): ProfileAction {
    return {
        type: ProfileActionType.RESET_EDIT_PASSWORD_SUCCESS,
    };
}

// Action creators related to account deletion
// FIXME: Change generic once Noop has published the endpoint.
export const createDeleteAccountAction = createUpdateAction<{}>(ProfileActionType.DELETE_ACCOUNT);
// FIXME: Change generic once Noop has published the endpoint.
export const createDeleteAccountSuccessAction = createUpdateSuccessAction<{}>(ProfileActionType.DELETE_ACCOUNT_SUCCESS);
export const createDeleteAccountErrorAction = createUpdateErrorAction(ProfileActionType.DELETE_ACCOUNT_ERROR);

export function createFetchProfileAction(): ProfileAction {
    return {
        type: ProfileActionType.FETCH,
    };
}

export function createFetchProfileSuccessAction(user: FrontUser): ProfileFetchAction {
    return {
        type: ProfileActionType.FETCH_SUCCESS,
        payload: user,
    };
}

export function createFetchProfileErrorAction(error: RequestError): ProfileFetchAction {
    return {
        type: ProfileActionType.FETCH_ERROR,
        payload: error,
    };
}

export function createClearProfileAction(): ProfileAction {
    return {
        type: ProfileActionType.CLEAR,
    };
}

export function createReactivateAccountRequestAction(description: string): ReactivateAccountRequestAction {
    return {
        type: ProfileActionType.REACTIVATE_ACCOUNT_REQUEST,
        description,
    };
}

export function createReactivateAccountRequestSuccessAction(): ReactivateAccountResponseAction {
    return {
        type: ProfileActionType.REACTIVATE_ACCOUNT_REQUEST_SUCCESS,
    };
}

export function createReactivateAccountRequestErrorAction(error: RequestError): ReactivateAccountResponseAction {
    return {
        type: ProfileActionType.REACTIVATE_ACCOUNT_REQUEST_ERROR,
        error,
    };
}
