import { FetchAction } from '../Fetch';
import { Movie } from '../../domain/work';
import { FavoritesActionType } from './FavoritesActionType';
import { RequestError } from '../../sagas/APICallSaga';
import { Favorite } from '../../domain/community';

export type FavoritesAction = FetchAction<FavoritesActionType, Favorite[]>;

export interface FavoriteMoviesAction extends FetchAction<FavoritesActionType, Movie[]> {
    maxMoviesToFetch?: number;
}

export function createFetchFavoritesAction(): FavoritesAction {
    return {
        type: FavoritesActionType.FETCH_FAVORITES,
    };
}

export function createFetchFavoritesSuccessAction(favorites: Favorite[]): FavoritesAction {
    return {
        type: FavoritesActionType.FETCH_FAVORITES_SUCCESS,
        payload: favorites,
    };
}

export function createFetchFavoritesErrorAction(error: RequestError): FavoritesAction {
    return {
        type: FavoritesActionType.FETCH_FAVORITES_ERROR,
        payload: error,
    };
}

export function createFetchFavoriteMoviesAction(maxMoviesToFetch?: number): FavoriteMoviesAction {
    return {
        type: FavoritesActionType.FETCH_FAVORITE_MOVIES,
        maxMoviesToFetch,
    };
}

export function createFetchFavoriteMoviesSuccessAction(favoriteMovies: Movie[]): FavoriteMoviesAction {
    return {
        type: FavoritesActionType.FETCH_FAVORITE_MOVIES_SUCCESS,
        payload: favoriteMovies,
    };
}

export function createFetchFavoriteMoviesErrorAction(error: RequestError): FavoriteMoviesAction {
    return {
        type: FavoritesActionType.FETCH_FAVORITE_MOVIES_ERROR,
        payload: error,
    };
}
