import throttle from 'lodash/throttle';

/**
 * Checks, if the device browser is a known touch only device.
 * Remember to update this list if we get bugs related to new touch devices!!
 * Checks:
 *      Android
 *      webOS
 *      iPhone
 *      iPad
 *      iPod
 *      BlackBerry
 *      IEMobile
 *      Opera Mini
 */
// FIXME: Can we memoize this since it can be run server side where userAgent is unavailable ?
function isKnownOnlyTouchDevice(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB10|PlayBook|IEMobile|ARM|Opera Mini/i.test(navigator.userAgent);
}

/**
 * Utility class that appends notifications of type paragraph to a DOM node.
 */
class DomNodeAggregator {
    private element: HTMLElement;

    public constructor(element: HTMLElement) {
        this.element = element;
    }

    public append(notification: string): void {
        const notificationElement: HTMLParagraphElement = document.createElement('p');
        notificationElement.innerText = notification;
        this.element.removeAttribute('aria-hidden');
        this.element.appendChild(notificationElement);
    }
}

/**
 * Service that can create aria notifications on a certain DOM node.
 *
 * In general, you probably want to use AriaLiveNotifications, which is better than this object.
 * @class AriaLiveNotificationsService
 */
export class AriaLiveNotificationsService {
    private readonly element: HTMLElement;
    private readonly rootElement: HTMLElement;
    private destroyed: boolean;
    private readonly aggregator: DomNodeAggregator;

    /**
     * Creates an instance of AriaLiveNotificationsService. An AriaLiveNotificationsService will
     * write notifications on a private HTMLElement. This private HTMLElement is appended
     * to a rootElement received by the constructor
     *
     * @param {HTMLElement} rootElement A root element where the notification element will be appended
     * @param {boolean} [isPolite=true] If this argument is true, the notification will be polite notifications, which
     * do not interrupt other notifications. If false, the notifications will be assertive, which interrupt other notifications.
     *
     * @memberOf AriaLiveNotificationsService
     */
    public constructor(rootElement: HTMLElement, isPolite: boolean = true) {
        this.rootElement = rootElement;
        this.element = this.createElement(isPolite);
        this.aggregator = new DomNodeAggregator(this.element);
        this.rootElement.appendChild(this.element);
        this.destroyed = false;

        this.hideAggregatorAfterUserInput = throttle(this.hideAggregatorAfterUserInput.bind(this), 1000);

        this.addListenersForClearingAggregator();
    }

    /**
     * The node on which we append the aria live notifications is visually hidden, but still available to screen readers.
     * We want to hide this node when the screen reader has finished reading from it so it wont be accessible through
     * item navigation.
     *
     * We cannot detect when a screen reader has finished reading a notification, but we know the following:
     * - all screen readers stop queued aria-live notifications when focusing a new element
     * - most mobile browsers trigger the focus event when navigating elements in item mode on a screen reader.
     * VoiceOver always fires focus on an item mode gesture, Talkback only fires focus when an element is activated.
     * - on desktop, we need to use the keyboard to navigate in item mode
     *
     * The aggregator node is hidden using aria-hidden when:
     * - keydown is caught on the window element
     * - focus is caught on the window element
     */
    private addListenersForClearingAggregator(): void {
        if (isKnownOnlyTouchDevice()) {
            window.addEventListener('focus', this.hideAggregatorAfterUserInput, true);
        } else {
            window.addEventListener('keydown', this.hideAggregatorAfterUserInput, true);
        }
    }

    private removeListenersForClearingNode(): void {
        window.removeEventListener(
            isKnownOnlyTouchDevice() ? 'focus' : 'keydown',
            this.hideAggregatorAfterUserInput,
            true
        );
    }

    private hideAggregatorAfterUserInput(): void {
        const isAggregatorHidden: boolean = this.element.getAttribute('aria-hidden') === 'true';
        if (!isAggregatorHidden) {
            this.element.setAttribute('aria-hidden', 'true');
        }
    }

    /**
     * Destroys the component, removing it from the rootNode passed in the constructor.
     *
     * Any attempt to use the object will throw an exception.
     */
    public destroy(): void {
        this.ensureNotDestroyed();

        if (this.rootElement.contains(this.element)) {
            this.rootElement.removeChild(this.element);
        }

        this.destroyed = true;

        this.removeListenersForClearingNode();
    }

    /**
     *  Remove all notifications on the internal DOM notification node.
     *
     *  Does not destroy the component.
     */
    public clearNotifications(): void {
        this.ensureNotDestroyed();

        while (this.element.firstChild != null) {
            this.element.removeChild(this.element.firstChild);
        }

        // The aria-live notification element must be removed and re-attached to the root element.
        // If not, a repeated announcement will only be announced once in Edge + Narrator.
        if (this.rootElement.contains(this.element)) {
            this.rootElement.removeChild(this.element);
        }
        this.rootElement.appendChild(this.element);
    }

    private createElement(isPolite: boolean): HTMLElement {
        const element: HTMLElement = document.createElement('div');

        element.className = isPolite ? 'AriaLiveNotifications-Polite' : 'AriaLiveNotifications-Assertive';

        const assertiveness: string = isPolite ? 'polite' : 'assertive';
        element.setAttribute('aria-live', assertiveness);
        element.setAttribute('aria-relevant', 'additions');
        return element;
    }

    /**
     * Send the message, after the delay specified.
     *
     * @param {string} message          Message to be appended to the internal node.
     * @param {number} [delay=0]        Delay after which the element is appended to the internal notification node.
     * Any delay smaller than 0 will be converted to 0.
     *
     * @memberOf AriaLiveNotificationsService
     */
    public notify(message: string, delay: number = 0): number {
        this.ensureNotDestroyed();

        return this.defer((): void => {
            this.aggregator.append(message);
        }, delay);
    }

    private defer(callback: Function, delay: number): number {
        if (delay < 0) {
            delay = 0;
        }
        return window.setTimeout((): void => {
            if (!this.destroyed) {
                callback();
            }
        }, delay);
    }

    private ensureNotDestroyed(): void {
        if (this.destroyed) {
            throw new Error('AriaLiveNotification service is destroyed, operation is illegal');
        }
    }
}
