import { takeLatest, call, put, take } from 'redux-saga/effects';
import * as actions from './RegistrationActions';
import { RegistrationActionType } from './RegistrationActionType';
import { callApi } from '../../sagas/APICallSaga';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';
import { EmailLookupStatus, RegistrationStep, EmailLookup } from './RegistrationStateReducer';
import { sendResetPasswordRequest, AuthActionType } from '../auth';

function* submitRegistration(action: actions.RegistrationAction): SagaIterator {
    const email = action.request!.email || '';
    yield call(callApi, {
        endpoint: {
            url: `email-lookup?q_email=${encodeURIComponent(email)}`,
        },
        onSuccess: (res: AxiosResponse<EmailLookup>): actions.EmailLookupResponseActionOnRegistration =>
            actions.createEmailLookupSuccessOnRegistrationAction(res.data, action),
        onError: actions.createEmailLookupErrorAction,
    });
}

function* lookupEmail(action: actions.EmailLookupRequestAction): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: `email-lookup?q_email=${encodeURIComponent(action.email)}`,
        },
        onSuccess: (res: AxiosResponse<EmailLookup>): actions.EmailLookupResponseAction =>
            actions.createEmailLookupSuccessAction(res.data),
        onError: actions.createEmailLookupErrorAction,
    });
}

function* onEmailLookupSuccess(action: actions.EmailLookupResponseAction): SagaIterator {
    const { status, user } = action.payload as EmailLookup;

    switch (status) {
        case EmailLookupStatus.NOT_FOUND:
            yield put(actions.createNextRegistrationStepAction());
            break;

        case EmailLookupStatus.PENDING:
            yield put(actions.createSetRegistrationStepAction(RegistrationStep.PENDING_REGISTRATION));
            break;

        case EmailLookupStatus.FOUND:
            if (user != null) {
                if (user.registerCompleted === true) {
                    yield put(actions.createSetRegistrationStepAction(RegistrationStep.EXISTING_ACCOUNT));
                } else {
                    yield put(sendResetPasswordRequest(user.email!));
                    const resetPasswordResultAction = yield take([
                        AuthActionType.SEND_RESET_PASSWORD_REQUEST_SUCCESS,
                        AuthActionType.SEND_RESET_PASSWORD_REQUEST_ERROR,
                    ]);
                    if (resetPasswordResultAction.type === AuthActionType.SEND_RESET_PASSWORD_REQUEST_SUCCESS) {
                        yield put(
                            actions.createSetRegistrationStepAction(RegistrationStep.RESET_PASSWORD_REQUEST_SENT)
                        );
                    } else {
                        yield put(
                            actions.createSetRegistrationStepAction(RegistrationStep.RESET_PASSWORD_REQUEST_ERROR)
                        );
                    }
                }
            }
            break;

        case EmailLookupStatus.REJECTED:
            yield put(actions.createSetRegistrationStepAction(RegistrationStep.REJECTED_ACCOUNT));
            break;

        default:
            break;
    }
}

function* onEmailLookupSuccessOnRegistration(action: actions.EmailLookupResponseActionOnRegistration): SagaIterator {
    const { status } = action.emailLookup as EmailLookup;

    if (status === EmailLookupStatus.NOT_FOUND) {
        yield call(callApi, {
            endpoint: {
                url: `registration-requests`,
                method: 'POST',
                body: action.action.request,
            },
            onSuccess: (res: AxiosResponse): actions.RegistrationAction =>
                actions.createSubmitRegistrationSuccessAction(res.data),
            onError: actions.createSubmitRegistrationErrorAction,
        });
    }
}

function* onSubmitSuccess(): SagaIterator {
    yield put(actions.createSetRegistrationStepAction(RegistrationStep.REGISTRATION_CONFIRMED));
}

function* onSubmitError(): SagaIterator {
    alert(
        "Une erreur s'est produite à l'inscription, veuillez contacter le support technique : https://ifcinema.institutfrancais.com/fr/contact"
    );
}

export default function* RegistrationSaga(): SagaIterator {
    yield takeLatest(RegistrationActionType.SUBMIT, submitRegistration);
    yield takeLatest(RegistrationActionType.SUBMIT_SUCCESS, onSubmitSuccess);
    yield takeLatest(RegistrationActionType.SUBMIT_ERROR, onSubmitError);
    yield takeLatest(RegistrationActionType.EMAIL_LOOKUP, lookupEmail);
    yield takeLatest(RegistrationActionType.EMAIL_LOOKUP_SUCCESS, onEmailLookupSuccess);
    yield takeLatest(RegistrationActionType.EMAIL_LOOKUP_SUCCESS_ON_REGISTRATION, onEmailLookupSuccessOnRegistration);
}
