import { AuthActionType } from './AuthActionType';
import { AuthAction, AuthTokenSuccessAction } from './AuthActions';
import { RequestError } from '../../sagas/APICallSaga';

export enum TokenStatus {
    EXPIRED = 'EXPIRED',
    VALID = 'VALID',
}

export enum LoginStatus {
    ANONYMOUS,
    AUTHENTICATED,
}

export interface AuthState {
    authenticatedOnOAuthServer: boolean;
    isAuthenticating: boolean;
    loginStatus: LoginStatus;
    token?: string;
    tokenStatus: TokenStatus;
    authError?: RequestError;
    tokenError?: string | null;
    resetPasswordError?: RequestError;
    resetPasswordRequestSent: boolean;
    confirmEmailError?: RequestError;
    confirmEmailRequestSent: boolean;
}

export const initialState: AuthState = {
    authenticatedOnOAuthServer: false,
    tokenStatus: TokenStatus.EXPIRED,
    isAuthenticating: false,
    loginStatus: LoginStatus.ANONYMOUS,
    resetPasswordRequestSent: false,
    confirmEmailRequestSent: false,
};

export const authReducer = (state: AuthState = initialState, action: AuthAction): AuthState => {
    switch (action.type) {
        case AuthActionType.TOKEN_REQUEST:
            return {
                ...state,
                tokenStatus: TokenStatus.EXPIRED,
                isAuthenticating: true,
            };

        case AuthActionType.TOKEN_ERROR:
            return {
                ...state,
                tokenError: action.payload,
                isAuthenticating: false,
            };

        case AuthActionType.TOKEN_SUCCESS:
            return {
                ...state,
                token: action.payload,
                tokenStatus: TokenStatus.VALID,
                tokenError: null,
                isAuthenticating: false,
                loginStatus: (action as AuthTokenSuccessAction).loginStatus,
            };

        case AuthActionType.AUTHENTICATE_ERROR:
            return {
                ...state,
                authenticatedOnOAuthServer: false,
                authError: action.payload,
            };

        case AuthActionType.AUTHENTICATE_SUCCESS:
            return {
                ...state,
                authenticatedOnOAuthServer: true,
                authError: undefined,
            };

        case AuthActionType.DEAUTHENTICATE:
            return {
                ...state,
                authenticatedOnOAuthServer: false,
                authError: undefined,
                loginStatus: LoginStatus.ANONYMOUS,
            };

        case AuthActionType.SEND_RESET_PASSWORD_REQUEST_ERROR:
        case AuthActionType.UPDATE_PASSWORD_ERROR:
            return {
                ...state,
                resetPasswordError: action.payload,
            };

        case AuthActionType.SEND_RESET_PASSWORD_REQUEST_SUCCESS:
        case AuthActionType.UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordError: undefined,
                resetPasswordRequestSent: true,
            };

        case AuthActionType.CONFIRM_EMAIL_SUCCESS:
            return {
                ...state,
                confirmEmailError: undefined,
                confirmEmailRequestSent: true,
            };

        case AuthActionType.CONFIRM_EMAIL_ERROR:
            return {
                ...state,
                confirmEmailError: action.payload,
            };

        default:
            return state;
    }
};
