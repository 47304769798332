import { Action } from 'redux';
import { AuthActionType } from './AuthActionType';
import { LoginStatus } from './AuthStateReducer';
import jwt from 'jsonwebtoken';
import { RequestError } from '../../sagas/APICallSaga';
import { EmailLookupStatus } from '../registration';

export interface AuthAction extends Action<AuthActionType> {
    payload?: any;
}

export interface AuthTokenSuccessAction extends AuthAction {
    loginStatus: LoginStatus;
}

export interface ResetPasswordRequestAction extends AuthAction {
    email: string;
}

export interface ResetPasswordEmailLookupSuccessAction extends AuthAction {
    status: EmailLookupStatus;
}

export interface UpdatePasswordRequestAction extends AuthAction {
    token: string;
    newPassword: string;
}

export interface EmailConfirmationRequestAction extends AuthAction {
    token: string;
}

export interface Credentials {
    email: string;
    password: string;
}

export function authenticationRequest(credentials: Credentials): AuthAction {
    return { type: AuthActionType.AUTHENTICATE_REQUEST, payload: credentials };
}

export function authenticationSuccess(): AuthAction {
    return { type: AuthActionType.AUTHENTICATE_SUCCESS };
}

export function authenticationError(error: RequestError): AuthAction {
    return { type: AuthActionType.AUTHENTICATE_ERROR, payload: error };
}

export function deauthenticate(): AuthAction {
    return { type: AuthActionType.DEAUTHENTICATE };
}

export function deauthenticationSuccess(): AuthAction {
    return { type: AuthActionType.DEAUTHENTICATE_SUCCESS };
}

export function authenticatedTokenRequest(): AuthAction {
    return { type: AuthActionType.TOKEN_REQUEST };
}

export function tokenSuccess(token: string): AuthTokenSuccessAction {
    const decodedToken = jwt.decode(token) as any;
    const loginStatus = decodedToken.client_id.includes('anonymous')
        ? LoginStatus.ANONYMOUS
        : LoginStatus.AUTHENTICATED;
    return { type: AuthActionType.TOKEN_SUCCESS, payload: token, loginStatus };
}

export function tokenError(error: string): AuthAction {
    return { type: AuthActionType.TOKEN_ERROR, payload: error };
}

export function sendResetPasswordRequest(email: string): ResetPasswordRequestAction {
    return { type: AuthActionType.SEND_RESET_PASSWORD_REQUEST, email };
}

export function resetPasswordEmailLookupSuccess(status: EmailLookupStatus): ResetPasswordEmailLookupSuccessAction {
    return { type: AuthActionType.RESET_PASSWORD_EMAIL_LOOKUP_SUCCESS, status };
}

export function resetPasswordEmailLookuError(error: RequestError): AuthAction {
    return { type: AuthActionType.RESET_PASSWORD_EMAIL_LOOKUP_ERROR, payload: error };
}

export function sendResetPasswordRequestSuccess(): AuthAction {
    return { type: AuthActionType.SEND_RESET_PASSWORD_REQUEST_SUCCESS };
}

export function sendResetPasswordRequestError(error: RequestError): AuthAction {
    return { type: AuthActionType.SEND_RESET_PASSWORD_REQUEST_ERROR, payload: error };
}

export function updatePassword(token: string, newPassword: string): UpdatePasswordRequestAction {
    return { type: AuthActionType.UPDATE_PASSWORD, token, newPassword };
}

export function updatePasswordSuccess(): AuthAction {
    return { type: AuthActionType.UPDATE_PASSWORD_SUCCESS };
}

export function updatePasswordError(error: RequestError): AuthAction {
    return { type: AuthActionType.UPDATE_PASSWORD_ERROR, payload: error };
}

export function confirmEmail(token: string): EmailConfirmationRequestAction {
    return { type: AuthActionType.CONFIRM_EMAIL, token };
}

export function confirmEmailSuccess(): AuthAction {
    return { type: AuthActionType.CONFIRM_EMAIL_SUCCESS };
}

export function confirmEmailError(error?: RequestError): AuthAction {
    return { type: AuthActionType.CONFIRM_EMAIL_ERROR, payload: error };
}
