export enum RegistrationActionType {
    SUBMIT = 'REGISTRATION/SUBMIT',
    SUBMIT_SUCCESS = 'REGISTRATION/SUBMIT_SUCCESS',
    SUBMIT_ERROR = 'REGISTRATION/SUBMIT_ERROR',
    EMAIL_LOOKUP = 'REGISTRATION/EMAIL_LOOKUP',
    EMAIL_LOOKUP_SUCCESS = 'REGISTRATION/EMAIL_LOOKUP_SUCCESS',
    EMAIL_LOOKUP_SUCCESS_ON_REGISTRATION = 'REGISTRATION/EMAIL_LOOKUP_SUCCESS_ON_REGISTRATION',
    EMAIL_LOOKUP_ERROR = 'REGISTRATION/EMAIL_LOOKUP_ERROR',
    CLEAR_EMAIL_LOOKUP = 'REGISTRATION/CLEAR_EMAIL_LOOKUP',
    SET_STEP = 'REGISTRATION/SET_STEP',
    NEXT_STEP = 'REGISTRATION/NEXT_STEP',
    PREV_STEP = 'REGISTRATION/PREV_STEP',
}
