import { FetchState } from '../Fetch';
import { FrontUser } from '../../domain/user';
import {
    ProfileAction,
    ProfileFetchAction,
    ReactivateAccountResponseAction,
    ProfileUpdateErrorAction,
    ProfileUpdateSuccessAction,
} from './ProfileActions';
import { ProfileActionType } from './ProfileActionType';
import { RequestError } from '../../sagas/APICallSaga';

export interface ProfileState extends FetchState<FrontUser> {
    reactivateAccountRequestSent: boolean;
    reactivateAccountRequestError?: RequestError;
    passwordUpdated: boolean;
    passwordUpdateError?: RequestError;
    emailUpdated: boolean;
    emailUpdateError?: RequestError;
}

export const initialState: ProfileState = {
    isLoading: false,
    reactivateAccountRequestSent: false,
    passwordUpdated: false,
    emailUpdated: false,
};

export const profileReducer = (state: ProfileState = initialState, action: ProfileAction): ProfileState => {
    switch (action.type) {
        case ProfileActionType.FETCH:
            return {
                ...state,
                isLoading: true,
            };

        case ProfileActionType.FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: (action as ProfileFetchAction).payload as FrontUser,
                error: undefined,
            };

        case ProfileActionType.FETCH_ERROR:
            return {
                ...state,
                isLoading: false,
                data: undefined,
                error: (action as ProfileFetchAction).payload as RequestError,
            };

        case ProfileActionType.CLEAR:
            return {
                ...state,
                data: undefined,
            };

        case ProfileActionType.REACTIVATE_ACCOUNT_REQUEST_SUCCESS:
            return {
                ...state,
                reactivateAccountRequestError: undefined,
                reactivateAccountRequestSent: true,
            };

        case ProfileActionType.REACTIVATE_ACCOUNT_REQUEST_ERROR:
            return {
                ...state,
                reactivateAccountRequestError: (action as ReactivateAccountResponseAction).error,
                reactivateAccountRequestSent: false,
            };

        case ProfileActionType.EDIT_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordUpdated: true,
                passwordUpdateError: undefined,
            };

        case ProfileActionType.RESET_EDIT_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordUpdated: false,
            };

        case ProfileActionType.EDIT_PASSWORD_ERROR:
            return {
                ...state,
                passwordUpdated: false,
                passwordUpdateError: (action as ProfileUpdateErrorAction).error,
            };

        case ProfileActionType.EDIT_EMAIL_SUCCESS:
            return {
                ...state,
                emailUpdated: true,
                data: (action as ProfileUpdateSuccessAction<FrontUser>).response,
                emailUpdateError: undefined,
            };

        case ProfileActionType.RESET_EDIT_EMAIL_SUCCESS:
            return {
                ...state,
                emailUpdated: false,
            };

        case ProfileActionType.EDIT_EMAIL_ERROR:
            return {
                ...state,
                emailUpdated: false,
                emailUpdateError: (action as ProfileUpdateErrorAction).error,
            };

        case ProfileActionType.UPDATE_SUCCESS:
            return {
                ...state,
                data: (action as ProfileUpdateSuccessAction<FrontUser>).response,
            };

        default:
            return state;
    }
};
