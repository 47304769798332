import { SagaIterator } from 'redux-saga';
import { all, call, take, fork, put, takeLatest } from 'redux-saga/effects';
import { AuthActionType } from './AuthActionType';
import axios, { AxiosPromise, AxiosResponse } from 'axios';
import {
    authenticationError,
    authenticationSuccess,
    authenticatedTokenRequest,
    Credentials,
    AuthTokenSuccessAction,
    ResetPasswordRequestAction,
    sendResetPasswordRequestSuccess,
    sendResetPasswordRequestError,
    UpdatePasswordRequestAction,
    updatePasswordSuccess,
    updatePasswordError,
    ResetPasswordEmailLookupSuccessAction,
    resetPasswordEmailLookupSuccess,
    resetPasswordEmailLookuError,
    EmailConfirmationRequestAction,
    confirmEmailSuccess,
    confirmEmailError,
    deauthenticationSuccess,
} from './AuthActions';
import { LoginStatus } from './AuthStateReducer';
import get from 'lodash/get';
import { HttpStatusCode } from '../../domain/HttpStatusCode';
import { EmailLookup, EmailLookupStatus } from '../registration';
import { callApi } from '../../sagas/APICallSaga';
import {
    createFetchProfileAction,
    createClearProfileAction,
    ProfileActionType,
    getReactivationRequests,
} from '../profile';
import { FrontUser } from '../../domain/user';
import { createToggleReactivateAccountModalAction } from '../UI';
import { AUTH_ENDPOINT } from './AuthService';
import FormData from 'form-data';
import { createFetchFavoritesAction } from '../favorites';

const OAUTH_STATE_URL: string = process.env.OAUTH_STATE_URL || `${AUTH_ENDPOINT}/oauth-state`;
const OAUTH_LOGIN_URL: string = process.env.OAUTH_LOGIN_URL || `${AUTH_ENDPOINT}/oauth-login`;
const OAUTH_LOGOUT_URL: string = process.env.OAUTH_LOGOUT_URL || `${AUTH_ENDPOINT}/logout`;

function getOauthState(): AxiosPromise<AxiosResponse> {
    return axios.get(OAUTH_STATE_URL, { withCredentials: true });
}

function authenticate(data: FormData): AxiosPromise<AxiosResponse> {
    return axios.post(OAUTH_LOGIN_URL, data, {
        withCredentials: true,
    });
}

function* deauthenticate(): SagaIterator {
    try {
        yield call(axios.get, OAUTH_LOGOUT_URL, {
            withCredentials: true,
        });
    } catch (e) {
        // console.log(e); // FIXME: Use a proper logging lib.
    } finally {
        yield put(deauthenticationSuccess());
        yield put(createClearProfileAction());
    }
}

function* checkAuthentication(): SagaIterator {
    const oauthStateResponse = yield call(getOauthState);

    if (oauthStateResponse.data.authenticated === true) {
        yield put(authenticationSuccess());
        yield put(authenticatedTokenRequest());
    }
}

function* authenticateUser({ email, password }: Credentials): SagaIterator {
    let csrfToken = null;

    try {
        const oauthStateResponse = yield call(getOauthState);
        csrfToken = oauthStateResponse.data.csrfToken;

        const loginFormData = new FormData();
        loginFormData.append('username', email);
        loginFormData.append('password', password);
        loginFormData.append('_csrf', csrfToken);

        const oauthLoginResponse = yield call(authenticate, loginFormData);

        if (oauthLoginResponse.data.authenticated) {
            yield put(authenticationSuccess());
            yield put(authenticatedTokenRequest());
        } else {
            yield put(
                authenticationError({
                    status: HttpStatusCode.INTERNAL_SERVER_ERROR,
                    statusText: 'Internal server error',
                    description: 'N/A',
                })
            );
        }
    } catch (err) {
        yield put(
            authenticationError({
                status: get(err, 'response.status', HttpStatusCode.INTERNAL_SERVER_ERROR),
                statusText: get(err, 'response.statusText', 'Internal server error'),
                description: get(err, 'response.data.message', 'N/A'),
            })
        );
    }
}

function* watchAuthenticationRequest(): SagaIterator {
    while (true) {
        const action = yield take(AuthActionType.AUTHENTICATE_REQUEST);
        yield fork(authenticateUser, action.payload);
        yield take([AuthActionType.AUTHENTICATE_SUCCESS, AuthActionType.AUTHENTICATE_ERROR]);
    }
}

export function* waitForAuthentication(): SagaIterator {
    let action: AuthTokenSuccessAction | undefined = undefined;
    while (action === undefined || (action as AuthTokenSuccessAction).loginStatus !== LoginStatus.AUTHENTICATED) {
        action = yield take(AuthActionType.TOKEN_SUCCESS);
    }
}

function* onTokenSuccess(action: AuthTokenSuccessAction): SagaIterator {
    if (action.loginStatus === LoginStatus.AUTHENTICATED) {
        yield put(createFetchProfileAction());
        yield put(createFetchFavoritesAction());

        const { payload: user }: { payload: FrontUser } = yield take(ProfileActionType.FETCH_SUCCESS);
        if (!user.enabled) {
            const usersHavingRequestedReactivation = yield call(getReactivationRequests);
            if (!usersHavingRequestedReactivation.includes(user.id)) {
                yield put(createToggleReactivateAccountModalAction());
            }
        }
    }
}

function resetPassword(data: FormData): AxiosPromise<AxiosResponse> {
    return axios.post(`${AUTH_ENDPOINT}/password-reset`, data, {
        withCredentials: true,
    });
}

function* lookupEmail(username: string): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: `email-lookup?q_email=${encodeURIComponent(username)}`,
        },
        onSuccess: (res: AxiosResponse<EmailLookup>): ResetPasswordEmailLookupSuccessAction =>
            resetPasswordEmailLookupSuccess(res.data.status),
        onError: resetPasswordEmailLookuError,
    });
}

function* sendResetPasswordRequest({ email }: ResetPasswordRequestAction): SagaIterator {
    let csrfToken = null;

    yield fork(lookupEmail, email);

    const action = yield take([
        AuthActionType.RESET_PASSWORD_EMAIL_LOOKUP_SUCCESS,
        AuthActionType.RESET_PASSWORD_EMAIL_LOOKUP_ERROR,
    ]);
    if (action.type === AuthActionType.RESET_PASSWORD_EMAIL_LOOKUP_ERROR) {
        yield put(sendResetPasswordRequestError(action.error));
        return;
    } else {
        if (action.status !== EmailLookupStatus.FOUND) {
            yield put(
                sendResetPasswordRequestError({
                    status: HttpStatusCode.NOT_FOUND,
                    statusText: 'User not found',
                    description: 'noUserFound',
                })
            );
            return;
        }
    }

    const oauthStateResponse = yield call(getOauthState);
    try {
        csrfToken = oauthStateResponse.data.csrfToken;

        const resetPasswordFormData = new FormData();
        resetPasswordFormData.append('username', email);
        resetPasswordFormData.append('_csrf', csrfToken);

        yield call(resetPassword, resetPasswordFormData);
        yield put(sendResetPasswordRequestSuccess());
    } catch (err) {
        yield put(
            sendResetPasswordRequestError({
                status: get(err, 'response.status', HttpStatusCode.INTERNAL_SERVER_ERROR),
                statusText: get(err, 'response.statusText', 'Internal server error'),
                description: get(err, 'response.data.message', 'N/A'),
            })
        );
    }
}

function confirmNewPassword(data: FormData): AxiosPromise<AxiosResponse> {
    return axios.post(`${AUTH_ENDPOINT}/password-confirm`, data, {
        withCredentials: true,
    });
}

function* updatePassword({ token, newPassword }: UpdatePasswordRequestAction): SagaIterator {
    let csrfToken = null;
    const oauthStateResponse = yield call(getOauthState);
    try {
        csrfToken = oauthStateResponse.data.csrfToken;

        const updatePasswordFormData = new FormData();
        updatePasswordFormData.append('requestToken', token);
        updatePasswordFormData.append('newPassword', newPassword);
        updatePasswordFormData.append('_csrf', csrfToken);

        yield call(confirmNewPassword, updatePasswordFormData);
        yield put(updatePasswordSuccess());
    } catch (err) {
        yield put(
            updatePasswordError({
                status: get(err, 'response.status', HttpStatusCode.INTERNAL_SERVER_ERROR),
                statusText: get(err, 'response.statusText', 'Internal server error'),
                description: get(err, 'response.data', 'N/A'),
            })
        );
    }
}

function* confirmEmail({ token }: EmailConfirmationRequestAction): SagaIterator {
    const body = new FormData();
    body.append('requestToken', token);

    yield call(callApi, {
        endpoint: {
            url: `users/account-confirm`,
            method: 'POST',
            body,
            additionalHeaders: body.getHeaders(),
        },
        onSuccess: confirmEmailSuccess,
        onError: confirmEmailError,
    });
}

export default function* authSaga(): SagaIterator {
    yield all([fork(checkAuthentication), fork(watchAuthenticationRequest)]);
    yield takeLatest(AuthActionType.TOKEN_SUCCESS, onTokenSuccess);
    yield takeLatest(AuthActionType.DEAUTHENTICATE, deauthenticate);
    yield takeLatest(AuthActionType.SEND_RESET_PASSWORD_REQUEST, sendResetPasswordRequest);
    yield takeLatest(AuthActionType.UPDATE_PASSWORD, updatePassword);
    yield takeLatest(AuthActionType.CONFIRM_EMAIL, confirmEmail);
}
