import { Action } from 'redux';

import { RequestError } from '../../sagas/APICallSaga';
import { FrontUser } from '../../domain/user';
import { RegistrationFormValues } from '../../components/Registration/Form';
import { FetchAction } from '../Fetch';

import { RegistrationActionType } from './RegistrationActionType';
import { EmailLookup, RegistrationStep } from './RegistrationStateReducer';
import * as actions from './RegistrationActions';

export type RegistrationRequest = Omit<RegistrationFormValues, 'acceptTermsAndConditionsOfUse'>;

export interface RegistrationAction extends FetchAction<RegistrationActionType, FrontUser> {
    user?: FrontUser;
    request?: RegistrationRequest;
}

export interface RegistrationStepAction extends Action<RegistrationActionType> {
    step?: RegistrationStep;
}
export interface EmailLookupRequestAction extends Action<RegistrationActionType> {
    email: string;
}

export interface EmailLookupResponseAction extends Action<RegistrationActionType> {
    payload: EmailLookup | RequestError;
}

export interface EmailLookupResponseActionOnRegistration extends Action<RegistrationActionType> {
    emailLookup: EmailLookup;
    action: actions.RegistrationAction;
}

export function createSubmitRegistrationAction(request: RegistrationRequest): RegistrationAction {
    return {
        type: RegistrationActionType.SUBMIT,
        request,
    };
}

export function createSubmitRegistrationSuccessAction(user: FrontUser): RegistrationAction {
    return {
        type: RegistrationActionType.SUBMIT_SUCCESS,
        payload: user,
    };
}

export function createEmailLookupSuccessOnRegistrationAction(
    payload: EmailLookup,
    action: actions.RegistrationAction
): EmailLookupResponseActionOnRegistration {
    return {
        type: RegistrationActionType.EMAIL_LOOKUP_SUCCESS_ON_REGISTRATION,
        emailLookup: payload,
        action: action,
    };
}

export function createSubmitRegistrationErrorAction(error: RequestError): RegistrationAction {
    return {
        type: RegistrationActionType.SUBMIT_ERROR,
        payload: error,
    };
}

export function createEmailLookupAction(email: string): EmailLookupRequestAction {
    return {
        type: RegistrationActionType.EMAIL_LOOKUP,
        email,
    };
}

export function createEmailLookupSuccessAction(payload: EmailLookup): EmailLookupResponseAction {
    return {
        type: RegistrationActionType.EMAIL_LOOKUP_SUCCESS,
        payload,
    };
}

export function createEmailLookupErrorAction(payload: RequestError): EmailLookupResponseAction {
    return {
        type: RegistrationActionType.EMAIL_LOOKUP_ERROR,
        payload,
    };
}

export function createClearEmailLookupAction(): RegistrationAction {
    return {
        type: RegistrationActionType.CLEAR_EMAIL_LOOKUP,
    };
}

export function createNextRegistrationStepAction(): RegistrationStepAction {
    return {
        type: RegistrationActionType.NEXT_STEP,
    };
}

export function createPrevRegistrationStepAction(): RegistrationStepAction {
    return {
        type: RegistrationActionType.PREV_STEP,
    };
}

export function createSetRegistrationStepAction(step: RegistrationStep): RegistrationStepAction {
    return {
        type: RegistrationActionType.SET_STEP,
        step,
    };
}
