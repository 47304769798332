import { RegistrationActionType } from './RegistrationActionType';
import {
    RegistrationAction,
    EmailLookupResponseAction,
    RegistrationStepAction,
    EmailLookupResponseActionOnRegistration,
} from './RegistrationActions';
import { FetchState } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';
import { FrontUser } from '../../domain/user';

export enum RegistrationStep {
    PROFILE_TYPE_SELECTION,
    PERSONAL_DATA_INPUT,
    GENERAL_PUBLIC_NOT_ALLOWED,
    REGISTRATION_CONFIRMED,
    EXISTING_ACCOUNT,
    REJECTED_ACCOUNT,
    PENDING_REGISTRATION,
    RESET_PASSWORD_REQUEST_SENT,
    RESET_PASSWORD_REQUEST_ERROR,
}

export enum EmailLookupStatus {
    FOUND = 'found',
    NOT_FOUND = 'not-found',
    REJECTED = 'rejected',
    PENDING = 'pending',
}

export interface EmailLookup {
    email: string;
    status: EmailLookupStatus;
    user?: FrontUser;
}

export interface RegistrationState extends FetchState<FrontUser> {
    step: RegistrationStep;
    emailLookup?: EmailLookup;
    emailLookupError?: RequestError;
}

export const initialState: RegistrationState = {
    isLoading: false,
    step: RegistrationStep.PROFILE_TYPE_SELECTION,
};

export const registrationReducer = (
    state: RegistrationState = initialState,
    action: RegistrationAction
): RegistrationState => {
    switch (action.type) {
        case RegistrationActionType.SET_STEP:
            return {
                ...state,
                step: (action as RegistrationStepAction).step!,
            };

        case RegistrationActionType.NEXT_STEP:
            return {
                ...state,
                step: state.step + 1,
            };

        case RegistrationActionType.PREV_STEP:
            return {
                ...state,
                step: state.step - 1,
            };

        case RegistrationActionType.SUBMIT:
            return {
                ...state,
                isLoading: true,
            };

        case RegistrationActionType.SUBMIT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
                data: action.payload as FrontUser,
            };

        case RegistrationActionType.SUBMIT_ERROR:
            return {
                ...state,
                isLoading: false,
                data: undefined,
                error: action.payload as RequestError,
            };

        case RegistrationActionType.EMAIL_LOOKUP_SUCCESS:
            return {
                ...state,
                emailLookupError: undefined,
                emailLookup: (action as EmailLookupResponseAction).payload as EmailLookup,
            };

        case RegistrationActionType.EMAIL_LOOKUP_ERROR:
            return {
                ...state,
                emailLookup: undefined,
                emailLookupError: action.payload as RequestError,
            };

        case RegistrationActionType.CLEAR_EMAIL_LOOKUP:
            return {
                ...state,
                emailLookup: undefined,
                emailLookupError: undefined,
            };
        case RegistrationActionType.EMAIL_LOOKUP_SUCCESS_ON_REGISTRATION:
            return {
                ...state,
                isLoading: false,
                emailLookup: (action as EmailLookupResponseActionOnRegistration).emailLookup,
                emailLookupError: undefined,
            };
        default:
            return state;
    }
};
