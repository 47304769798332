import { AnyAction } from 'redux';
import filter from 'lodash/filter';
import { RequestError } from '../../sagas/APICallSaga';
import { FavoritesActionType } from './FavoritesActionType';
import { Movie } from '../../domain/work';
import { Favorite, FavoriteType } from '../../domain/community';
import { FetchState } from '../Fetch';

export type FavoritesState = FetchState<Favorite[]>;

export type FavoriteMoviesState = FetchState<Movie[]>;

const favoritesInitialState: FavoritesState = {
    isLoading: false,
};

const favoriteMoviesInitialState: FavoriteMoviesState = {
    isLoading: false,
};

export const favoritesReducer = (state: FavoritesState = favoritesInitialState, action: AnyAction): FavoritesState => {
    switch (action.type) {
        case FavoritesActionType.FETCH_FAVORITES:
            return {
                ...state,
                isLoading: true,
            };

        case FavoritesActionType.FETCH_FAVORITES_SUCCESS:
            const data = action.payload as Favorite[];
            return {
                ...state,
                isLoading: false,
                error: undefined,
                data: filter(data, (item: Favorite): boolean => item.type === FavoriteType.MOVIE),
            };

        case FavoritesActionType.FETCH_FAVORITES_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload as RequestError,
            };

        default:
            return state;
    }
};

export const favoriteMoviesReducer = (
    state: FavoriteMoviesState = favoriteMoviesInitialState,
    action: AnyAction
): FavoriteMoviesState => {
    switch (action.type) {
        case FavoritesActionType.FETCH_FAVORITE_MOVIES:
            return {
                ...state,
                isLoading: true,
            };

        case FavoritesActionType.FETCH_FAVORITE_MOVIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
                data: action.payload as Movie[],
            };

        case FavoritesActionType.FETCH_FAVORITE_MOVIES_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload as RequestError,
            };

        default:
            return state;
    }
};
